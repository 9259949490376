@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tab {
  @media (min-width: #{$tab-width}) {
    @content;
  }
}

@mixin mob-land {
  @media (min-width: #{$mobile-landscape}) {
    @content;
  }
}

@mixin retina {
  @media (min-width: #{$retina-width}) {
    @content;
  }
}
