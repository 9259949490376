.register, .login-bg {
	height: 100vh;
	padding-top: 10px;
	background-image: url('../../../images/bg1.jpg');
	padding-bottom: 20px;
	overflow-y: scroll;
}
.is-boxed {
    background: color-bg(body-outer);


	.body-wrap {
		max-width: $container--width-boxed;
	    margin: 0 auto;
	    box-shadow: color-bg(body-shadow);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: color-bg(body);
			z-index: -10;
		}
	}
}

.body-wrap {
	position: relative;
    overflow: hidden;
    // Sticky footer
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-content {
    flex: 1 0 auto;
}

body,h1 {
	font-family: 'Sansita';
}


.subtitle{
	/* Title */

// position: absolute;
// width: 222px;
// height: 30px;
// left: 592px;
// top: 3500px;

font-family: 'Sansita';
font-style: normal;
font-weight: 400;
font-size: 17px;
// line-height: 150%;
/* identical to box height, or 30px */
text-align: center;
margin: 0;
padding: 0;
color: #616161;
margin-top: 30px;


}
.title{
font-family: 'Sansita';
font-style: normal;
font-weight: 700;
font-size: 40px;
text-align: center;
line-height: .5;
margin: 0;
padding: 0;
margin-bottom: 50px;
// color: #3A3A3A;
}

.banner{
	
	// position: absolute;
	width: 100%;
	height: 600px;
	left: 0px;
	// top: 95.7px;
	
	
	
	/* Mask */
	
	// position: absolute;
	// left: 0%;
	// right: 0%;
	// top: 1.98%;
	// bottom: 83.19%;
	
	// background: #44AEE8;
	
	
	/* Mask */
	
	// position: absolute;
	// left: 0%;
	// right: 0%;
	// top: 1.98%;
	// bottom: 83.19%;
	
	background: black;
	
	background: url("../../../images/slide6.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
    padding: 10px 30px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.page-banner{
	
	// position: absolute;
	width: 100%;
	height: 250px;
	left: 0px;
	background: black;
	
	background: url("../../../images/bg1.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
    // padding: 10px 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.page-banner-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px 30px;
    background: rgba(0 0 0 / 50%);
}
.breadcumb{
	color: white;
}


.banner-inner{
	width: 100%;
    max-width: 500px;
    /* height: 443px; */
    display: flex;
    // background: #FFF3E3;
    background: #22C4B5DF;
    padding: 10px;
    border-radius: 10px;
}



.banner-inner .txt{
	padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 561px;
}



.banner-inner .txt .title{
	left: 54.17%;
	// right: 7.01%;
	// top: calc(50% - 127px/2 - 2011px);
	
	// font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 52px;
	line-height: 65px;
	/* or 125% */
	
	/* Primary */
	text-align: start;
    // line-height: 1;
	// color: #B88E2F;
	color: #091D1B;
}


.banner-inner .txt .desc{

	/* Treating all skin co */
	left: 54.17%;
	right: 7.92%;
	top: 10.07%;
	bottom: 88.85%;
	
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	/* or 133% */
	
	/* Font / Color */
	color: #333333;
}



.banner-inner-btn {

	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height */
	text-transform: uppercase;
	
	color: #FFFFFF;
	// flex: none;
	// order: 0;
	// flex-grow: 0;
}


.banner-inner-tag{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 3px;
	
	color: #333333;
}

.exhibition{
padding: 20px;
display: flex;
// background: #FCF8F3;
background: #22C4B5DF;
justify-content: space-evenly;
flex-wrap: wrap;

}
.exhibition .inner-left{
	width:100%;
	max-width: 400px;
}

.exhibition .inner-right{
// width: 806px;
// max-width: 90%;
// height: 582px;
// left: 564px;
top: 2807px;
display: flex;
flex-direction: row;
}

// .art-exhibition {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	max-width: 800px;
// 	margin: auto;
//   }
  
 .exhibition .inner-right .active {
	display: none;
	border: 2px solid #ddd;
	// width: 100%;
	padding: 20px;
	margin-bottom: 10px;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
width: 250px;
height: 350px;
left: 564px;
top: 2807px;


  }
  
  .exhibitions {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	overflow: hidden;
}

.artworks {
	margin-top: 20px;
	display: flex;
	width: 600px;
	// overflow: hidden;
	white-space: nowrap;
	transition: transform 0.5s ease-in-out;
  }
  
  .art-slide {
	flex: 0 0 200px;
	margin-right: 10px;
	padding: 15px;
	border: 1px solid #ddd;
	text-align: center;
	font-size: 16px;
	width: 250px;
	height: 270px;
	// left: 992px;
  }
  
  .next-arrow {
	position: absolute;
	right: 0;
	padding: 10px 10px;
	margin-right: 5px;
	// background: #007bff;
	color: #091D1B;
	border: none;
	cursor: pointer;
	z-index: 9;
	transition: background-color 0.3s;
	border-radius: 90%;
	position-area: left;
	background: #FFFFFF;
	box-shadow: 2px 4px 5px 1px rgba(0, 0, 0, 0.16);
  }
  
  .next-arrow:hover {
	// background: #0056b3;
  }
  
  .indicators {
	margin-top: 10px;
  }
  
  .indicator {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	background: #ddd;
	border-radius: 50%;
	transition: background 0.3s;
	cursor: pointer;
  }
  
  .indicator.active {
	background: #091D1B;
	// border: #007bff 1px solid;
	/* Active */
	padding: 6px;
	flex: none;
	order: 0;
	flex-grow: 0;

  }

  .download-btn{
	display: flex;
	justify-content: center;
	align-items: center;
  }
.shop-band {
	padding: 5px 30px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: #22C4B5AF;
}
.sb-left{
	display: flex;
	justify-content: center;
	align-items: center;
}
.sb-right {
	display: flex;
	justify-content: center;
	margin: 5px 0px;
}
.filter {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
}
.f-icon {
	display: flex;
	gap: 5px;
}
.f-icon img {
	width: 25px;
}
.filter p, .sb-form p{
	margin: 0px;
	font-size: 15px;
	font-family: 'Sansita';
	font-weight: 100;
	color: #091D1B;
}

.page-numbers{
	border-left: black 2px solid;
    padding: 5px 10px;
    margin: 2px 5px;
    color: black;
}

.sb-form{
	display: flex;
	justify-content: center;
	align-items: center;
}
.sb-form input {
width: 100px;
height: 30px;
background: #FFFFFF;
border: none;
margin: 0px 5px;
padding: 10px;

font-family: 'Sansita';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #9F9F9F;
text-align: center;

}
.cart_container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
 }
.cart_left {
	width: 100%;
	max-width: 800px;
}
.cart_item{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0px;
	padding: 10px;
	border: 1px solid #ddd;
}
.cart-quan{
	/* Rectangle 58 */

box-sizing: border-box;
padding: 2px 6px;
margin: 0px 7px;
border: 1px solid #9F9F9F;
border-radius: 5px;
font-family: 'Sansita';
font-style: normal;
font-weight: 400;
font-size: 16px;

color: #000000;
}
.cart_right{
padding: 20px 30px;
margin: 20px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
background: #caedea;
}
.cart_right span {
	display: flex;
		justify-content: space-between;
		flex-direction: row;
		width: 100%;
}
.cart_right h2{
	// font-family: 'Sansita';
	// font-style: normal;
	// font-weight: 500;
	font-size: 25px;
	margin: 20px;
	color: black;
}
.cart_right span .total{
font-family: 'Sansita';
font-style: normal;
font-weight: 500;
font-size: 20px;
color: #22C4B5;
}

.checkout-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem;
	// padding: 2rem;
	max-width: 1200px;
	margin: 0 auto;
	
	@media (max-width: 768px) {
	  grid-template-columns: 1fr;
	}
  }
  
  .checkout-left {
	background: #fff;
	padding: 2rem;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
	h2 {
	  color: #333;
	  margin-bottom: 1.5rem;
	  font-size: 1.5rem;
	}
  
	input[type="text"] {
	  width: 100%;
	  padding: 12px;
	  border: 1px solid #ddd;
	  border-radius: 4px;
	  margin-bottom: 1.5rem;
	  font-size: 1rem;
  
	  &:focus {
		border-color: #22C4B5;
		outline: none;
		box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
	  }
	}
  
	ul {
	  list-style: none;
	  padding: 0;
  
	  li {
		padding: 1rem;
		border: 1px solid #ddd;
		border-radius: 4px;
		margin-bottom: 0.5rem;
		cursor: pointer;
		transition: all 0.3s ease;
  
		&:hover {
		  background: #f8f9fa;
		  border-color: #007bff;
		}
  
		&.selected {
		  background: #e7f5ff;
		  border-color: #007bff;
		}
	  }
	}
  }
  
  .checkout-right {
	background: #fff;
	padding: 2rem;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
	h2 {
	  color: #333;
	  margin-bottom: 1.5rem;
	  font-size: 1.5rem;
	}
  
	table {
	  width: 100%;
	  border-collapse: collapse;
	  margin-bottom: 1.5rem;
  
	  th, td {
		padding: 0.75rem;
		border-bottom: 1px solid #ddd;
		text-align: left;
	  }
  
	  th {
		background: #f8f9fa;
		font-weight: 600;
	  }
	}
  
	p {
	  display: flex;
	  justify-content: space-between;
	  margin-bottom: 0.5rem;
	  font-size: 1rem;
	}
  
	select {
	  width: 100%;
	  padding: 12px;
	  border: 1px solid #ddd;
	  border-radius: 4px;
	  margin-bottom: 1.5rem;
	  font-size: 1rem;
	  background: #fff;
  
	  &:focus {
		border-color: #007bff;
		outline: none;
	  }
	}
  
	button {
	//   width: 100%;
	//   padding: 1rem;
	//   background: #007bff;
	//   color: #fff;
	//   border: none;
	  border-radius: 4px;
	  font-size: 1rem;
	  font-weight: 600;
	  cursor: pointer;
	  transition: background 0.3s ease;
  
	  &:hover {
		background: #0056b3;
	  }
	}
  }
  
  .total-section {
	margin-top: 1.5rem;
	padding-top: 1.5rem;
	border-top: 2px solid #ddd;
  
	p {
	  font-weight: 600;
	  font-size: 1.1rem;
	}
  }
  @media (max-width : 768px) {
	.inner-right {
		max-width: 80%;
	}
  }

  .product-details {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 3rem;
	padding: 2rem;
	max-width: 1400px;
	margin: 0 auto;
  
	@media (max-width: 768px) {
	  grid-template-columns: 1fr;
	}
  
	.image-section {
	  display: flex;
	  gap: 1rem;
  
	  .images-list {
		ul {
		  list-style: none;
		  padding: 0;
		  margin: 0;
		  display: flex;
		  flex-direction: column;
		  gap: 0.5rem;
  
		  li {
			width: 80px;
			height: 80px;
			border: 2px solid transparent;
			cursor: pointer;
			transition: all 0.3s ease;
  
			&:hover, &.active {
			  border-color: #007bff;
			}
  
			img {
			  width: 100%;
			  height: 100%;
			  object-fit: cover;
			}
		  }
		}
	  }
  
	  .main-prod-image {
		width: 100%;
		max-height: 600px;
		object-fit: contain;
		cursor: zoom-in;
	  }
	}
  
	.desc-section {
	  .prod-name {
		font-size: 2rem;
		margin-bottom: 1rem;
	  }
  
	  .prod-price {
		font-size: 1.5rem;
		color: #091D1B;
		margin-bottom: 1.5rem;
  
		s {
		  color: #666;
		  margin-left: 1rem;
		  font-size: 1rem;
		}
	  }
  
	  .prod-desc {
		line-height: 1.6;
		margin-bottom: 2rem;
	  }
  
	  .variants-section {
		margin-bottom: 2rem;
  
		.variant-title {
		  font-weight: 600;
		  margin-bottom: 0.5rem;
		}
  
		.variant-options {
		  display: flex;
		  flex-wrap: wrap;
		  gap: 0.5rem;
  
		  .variant-option {
			padding: 0.5rem 1rem;
			border: 1px solid #ddd;
			border-radius: 4px;
			cursor: pointer;
			transition: all 0.3s ease;
  
			&:hover {
			  border-color: #091D1B;
			}
  
			&.selected {
			  background: #22C4B5;
			  color: white;
			  border-color: #22C4B5;
			}
		  }
		}
	  }
	}
  }
  
  .quantity-controls {
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-bottom: 2rem;
  
	button {
	  width: 40px;
	  height: 40px;
	  border-radius: 50%;
	  border: none;
	  background: #f0f0f0;
	  cursor: pointer;
	  transition: all 0.3s ease;
  
	  &:hover {
		background: #22C4B5;
	  }
  
	  &:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	  }
	}
  
	span {
	  font-size: 1.2rem;
	  min-width: 40px;
	  text-align: center;
	}
  }
  
  .add-to-cart-btn {
	width: 100%;
	padding: 1rem;
	font-size: 1.1rem;
	position: relative;
	border: none;
  
	&.loading {
	  color: transparent;
  
	  .loader {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 20px;
		height: 20px;
		border: 2px solid #fff;
		border-radius: 50%;
		border-top-color: transparent;
		animation: spin 1s linear infinite;
	  }
	}
  }
  
  @keyframes spin {
	to { transform: translate(-50%, -50%) rotate(360deg); }
  }

  .related-products-section {
	padding: 2rem;
	margin-top: 3rem;
  
	.section-title {
	  font-size: 1.5rem;
	  margin-bottom: 1.5rem;
	  padding-bottom: 0.5rem;
	  border-bottom: 2px solid #eee;
	}
  
	.products-grid {
	  display: grid;
	  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	  gap: 20px;
	  
	  @media (max-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
		gap: 15px;
	  }
	}
  
	.product-card {
	  cursor: pointer;
	  transition: transform 0.3s ease;
	  background: white;
	  border-radius: 8px;
	  overflow: hidden;
	  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
	  &:hover {
		transform: translateY(-5px);
	  }
  
	  .product-image {
		width: 100%;
		height: 200px;
		overflow: hidden;
  
		img {
		  width: 100%;
		  height: 100%;
		  object-fit: cover;
		  transition: transform 0.3s ease;
  
		  &:hover {
			transform: scale(1.1);
		  }
		}
	  }
  
	  .product-info {
		padding: 1rem;
  
		h3 {
		  margin: 0;
		  font-size: .75rem;
		  margin-bottom: 0.5rem;
		  color: #333;
		}
  
		.price {
		  font-weight: 600;
		  color: #091D1B;
		  margin: 0;
		}
	  }
	}
  }

  .page-loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .loader-container {
        text-align: center;

        p {
            margin-top: 1rem;
            color: #666;
            font-size: 1.1rem;
        }

        .loader {
            width: 50px;
            height: 50px;
            border: 3px solid #f3f3f3;
            border-top: 3px solid #007bff;
            border-radius: 50%;
            animation: spin 1s linear infinite;
            margin: 0 auto;
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

// Add this to existing loading button styles
.add-to-cart-btn {
    &.loading {
        position: relative;
        color: transparent !important;

        &::after {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            margin: -10px 0 0 -10px;
            border: 2px solid rgba(255,255,255,0.3);
            border-top-color: #fff;
            border-radius: 50%;
            animation: spin 1s ease-in-out infinite;
        }
    }
}

// .checkout-container {
// 	max-width: 1200px;
// 	margin: 2rem auto;
// 	padding: 0 1rem;
// 	display: grid;
// 	grid-template-columns: 1.5fr 1fr;
// 	gap: 2rem;
  
// 	@media (max-width: 768px) {
// 	  grid-template-columns: 1fr;
// 	}
  
// 	h2 {
// 	  font-size: 1.5rem;
// 	  margin-bottom: 1.5rem;
// 	  color: #333;
// 	  border-bottom: 2px solid #eee;
// 	  padding-bottom: 0.5rem;
// 	}
  
// 	.checkout-left {
// 	  background: white;
// 	  padding: 2rem;
// 	  border-radius: 8px;
// 	  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

// 	  .address-section {
// 		background: white;
// 		// padding: 2rem;
// 		border-radius: 8px;
// 		// box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
  
// 	  .address-form {
//         display: flex;
//         flex-direction: column;
//         gap: 1rem;
//         max-width: 100%;

//         input, 
//         textarea, 
//         select,
//         .autocomplete-input {
//           width: 100%;
//           padding: 12px;
//           border: 1px solid #ddd;
//           border-radius: 4px;
//           font-size: 1rem;
  
// 		  &:focus {
// 			outline: none;
// 			border-color: #007bff;
// 			box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
// 		  }
// 		}
  
// 		.address-details {
// 		  display: grid;
// 		  grid-template-columns: repeat(3, 1fr);
// 		  gap: 1rem;
  
// 		  @media (max-width: 576px) {
// 			grid-template-columns: 1fr;
// 		  }
// 		}
// 		textarea {
// 			resize: vertical;
// 			min-height: 100px;
// 		  }
// 		  .get-delivery-options {
// 			margin-top: 1rem;
// 			width: 100%;
// 			padding: 1rem;
// 		  }
// 	  }
// 	}
	
  
// 	  .delivery-options {
// 		margin-top: 2rem;
  
// 		ul {
// 		  list-style: none;
// 		  padding: 0;
  
// 		  li {
// 			padding: 1rem;
// 			border: 1px solid #ddd;
// 			border-radius: 4px;
// 			margin-bottom: 1rem;
// 			cursor: pointer;
// 			display: flex;
// 			align-items: center;
// 			gap: 1rem;
// 			transition: all 0.3s ease;
  
// 			&:hover {
// 			  border-color: #007bff;
// 			  background: #f8f9fa;
// 			}
  
// 			&.selected {
// 			  border-color: #007bff;
// 			  background: #e7f1ff;
// 			}
  
// 			img {
// 			  width: 40px;
// 			  height: 40px;
// 			  object-fit: contain;
// 			}
  
// 			.courier-info {
// 			  flex: 1;
  
// 			  .courier-name {
// 				font-weight: 600;
// 			  }
  
// 			  .courier-details {
// 				color: #666;
// 				font-size: 0.9rem;
// 			  }
// 			}
// 		  }
// 		}
// 	  }
// 	}
  
// 	.checkout-right {
// 	  background: white;
// 	  padding: 2rem;
// 	  border-radius: 8px;
// 	  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
// 	  height: fit-content;
// 	  position: sticky;
// 	  top: 2rem;
  
// 	  .summary-item {
// 		display: flex;
// 		justify-content: space-between;
// 		margin-bottom: 1rem;
		
// 		.label {
// 		  color: #666;
// 		}
		
// 		.value {
// 		  font-weight: 600;
// 		}
// 	  }
  
// 	  .coupon-section {
// 		margin: 2rem 0;
// 		padding: 1rem;
// 		background: #f8f9fa;
// 		border-radius: 4px;
  
// 		.coupon-input {
// 		  display: flex;
// 		  gap: 0.5rem;
  
// 		  input {
// 			flex: 1;
// 			padding: 8px 12px;
// 			border: 1px solid #ddd;
// 			border-radius: 4px;
// 		  }
// 		}
  
// 		.error-message {
// 		  color: #dc3545;
// 		  margin-top: 0.5rem;
// 		  font-size: 0.9rem;
// 		}
  
// 		.discount-amount {
// 		  color: #28a745;
// 		  margin-top: 0.5rem;
// 		  font-weight: 600;
// 		}
// 	  }
  
// 	  .total {
// 		font-size: 1.25rem;
// 		font-weight: 600;
// 		margin-top: 2rem;
// 		padding-top: 1rem;
// 		border-top: 2px solid #eee;
// 	  }
// 	}
//   }

// .checkout-container {
// 	max-width: 1200px;
// 	margin: 2rem auto;
// 	padding: 0 1rem;
// 	display: grid;
// 	grid-template-columns: 1.5fr 1fr;
// 	gap: 2rem;
  
// 	@media (max-width: 768px) {
// 	  grid-template-columns: 1fr;
// 	}
  
// 	.checkout-left {
// 	  .address-section {
// 		background: white;
// 		// padding: 2rem;
// 		// border-radius: 8px;
// 		// box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
// 		h2 {
// 		  margin-bottom: 1.5rem;
// 		  padding-bottom: 0.5rem;
// 		  border-bottom: 2px solid #eee;
// 		}
  
// 		.address-form {
// 		  display: flex;
// 		  flex-direction: column;
// 		  gap: 1rem;
// 		  max-width: 100%;
  
// 		  input, 
// 		  textarea, 
// 		  select,
// 		  .autocomplete-input {
// 			width: 100%;
// 			padding: 12px;
// 			border: 1px solid #ddd;
// 			border-radius: 4px;
// 			font-size: 1rem;
  
// 			&:focus {
// 			  outline: none;
// 			  border-color: #007bff;
// 			  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
// 			}
// 		  }
  
// 		  .address-details {
// 			display: grid;
// 			grid-template-columns: repeat(3, 1fr);
// 			gap: 1rem;
  
// 			@media (max-width: 576px) {
// 			  grid-template-columns: 1fr;
// 			}
// 		  }
  
// 		  textarea {
// 			resize: vertical;
// 			min-height: 100px;
// 		  }
  
// 		  .get-delivery-options {
// 			margin-top: 1rem;
// 			width: 100%;
// 			padding: 1rem;
// 		  }
// 		}
// 	  }
// 	  .delivery-options {
// 		margin-top: 2rem;
// 		background: white;
// 		padding: 2rem;
// 		border-radius: 8px;
// 		box-shadow: 0 2px 4px rgba(0,0,0,0.1);
	
// 		ul {
// 		  list-style: none;
// 		  padding: 0;
// 		  margin: 0;
	
// 		  li {
// 			display: flex;
// 			align-items: center;
// 			gap: 1rem;
// 			padding: 1rem;
// 			border: 1px solid #ddd;
// 			border-radius: 4px;
// 			margin-bottom: 1rem;
// 			cursor: pointer;
// 			transition: all 0.3s ease;
	
// 			&:hover {
// 			  border-color: #007bff;
// 			  background: #f8f9fa;
// 			}
	
// 			&.selected {
// 			  border-color: #007bff;
// 			  background: #e7f1ff;
// 			}
	
// 			img {
// 			  width: 40px;
// 			  height: 40px;
// 			  object-fit: contain;
// 			}
	
// 			.courier-info {
// 			  flex: 1;
			  
// 			  .courier-name {
// 				font-weight: 600;
// 			  }
	
// 			  .courier-details {
// 				color: #666;
// 				font-size: 0.9rem;
// 			  }
// 			}
// 		  }
// 		}
// 	  }
// 	}
  
// 	.checkout-right {
// 	  background: white;
// 	  padding: 2rem;
// 	  border-radius: 8px;
// 	  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
// 	  height: fit-content;
// 	  position: sticky;
// 	  top: 2rem;

// 	  .order-summary {
// 		background: white;

// 		h2 {
// 		  margin-bottom: 1.5rem;
// 		  padding-bottom: 0.5rem;
// 		  border-bottom: 2px solid #eee;
// 		}
  
// 		.summary-item {
// 			display: flex;
// 			justify-content: space-between;
// 			margin-bottom: 1rem;
			
// 			.label {
// 			color: #666;
// 			}
			
// 			.value {
// 			font-weight: 600;
// 			}
// 		}
	
// 		.coupon-section {
// 			margin: 2rem 0;
// 			padding: 1rem;
// 			background: #f8f9fa;
// 			border-radius: 4px;
	
// 			.coupon-input {
// 			display: flex;
// 			gap: 0.5rem;
	
// 			input {
// 				flex: 1;
// 				padding: 8px 12px;
// 				border: 1px solid #ddd;
// 				border-radius: 4px;
// 			}
// 			}
	
// 			.error-message {
// 			color: #dc3545;
// 			margin-top: 0.5rem;
// 			font-size: 0.9rem;
// 			}
	
// 			.discount-amount {
// 			color: #28a745;
// 			margin-top: 0.5rem;
// 			font-weight: 600;
// 			}
// 		}
	
// 		.total {
// 			font-size: 1.25rem;
// 			font-weight: 600;
// 			margin-top: 2rem;
// 			padding-top: 1rem;
// 			border-top: 2px solid #eee;
// 		}
// 		}
// 	}
//   }

.checkout-container {
	max-width: 1200px;
	margin: 1rem auto;
	padding: 0 1rem;
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	gap: 2rem;
  
	@media (max-width: 768px) {
	  grid-template-columns: 1fr;
	  padding: 0.5rem;
	  gap: 1rem;
	  margin: 0.5rem;
	}
  
	.checkout-left {
	  .address-section {
		background: white;
		padding: 1rem;
  
		@media (max-width: 768px) {
		  padding: 0.5rem;
		}
  
		h2 {
		  margin-bottom: 1rem;
		  padding-bottom: 0.5rem;
		  border-bottom: 2px solid #eee;
		  font-size: 1.25rem;
  
		  @media (max-width: 768px) {
			font-size: 1.1rem;
		  }
		}
  
		.address-form {
		  display: flex;
		  flex-direction: column;
		  gap: 0.75rem;
		  width: 100%;
  
		  input, 
		  textarea, 
		  select,
		  .autocomplete-input {
			width: 100%;
			padding: 10px;
			border: 1px solid #ddd;
			border-radius: 4px;
			font-size: 1rem;
  
			@media (max-width: 768px) {
			  padding: 8px;
			  font-size: 0.9rem;
			}
		  }
  
		  .address-details {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 1rem;
  
			@media (max-width: 768px) {
			  grid-template-columns: 1fr;
			  gap: 0.75rem;
			}
		  }
  
		  textarea {
			resize: vertical;
			min-height: 80px;
		  }
  
		//   .get-delivery-options {
		// 	margin-top: 0.75rem;
		// 	width: 100%;
		// 	padding: 0.75rem;
		//   }
		}
	  }
  
	  .delivery-options {
		margin-top: 1rem;
		background: white;
		padding: 1rem;
		border-radius: 8px;
		box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
		@media (max-width: 768px) {
		  padding: 0.5rem;
		}
  
		ul {
		  list-style: none;
		  padding: 0;
		  margin: 0;
  
		  li {
			display: flex;
			align-items: center;
			gap: 0.75rem;
			padding: 0.75rem;
			border: 1px solid #ddd;
			border-radius: 4px;
			margin-bottom: 0.75rem;
			cursor: pointer;
  
			@media (max-width: 768px) {
			  padding: 0.5rem;
			  font-size: 0.9rem;
			}
  
			img {
			  width: 35px;
			  height: 35px;
			  object-fit: contain;
  
			  @media (max-width: 768px) {
				width: 30px;
				height: 30px;
			  }
			}
  
			.courier-info {
			  flex: 1;
			  
			  .courier-name {
				font-weight: 600;
				font-size: 0.95rem;
			  }
  
			  .courier-details {
				color: #666;
				font-size: 0.85rem;
			  }
			}
		  }
		}
	  }
	}
  
	.checkout-right {
	  background: white;
	  padding: 1rem;
	  border-radius: 8px;
	  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
	  height: fit-content;
	  position: sticky;
	  top: 1rem;
  
	  @media (max-width: 768px) {
		position: relative;
		top: 0;
		margin-bottom: 1rem;
	  }
  
	  .order-summary {
		h2 {
		  margin-bottom: 1rem;
		  padding-bottom: 0.5rem;
		  border-bottom: 2px solid #eee;
		  font-size: 1.25rem;
  
		  @media (max-width: 768px) {
			font-size: 1.1rem;
		  }
		}
  
		.summary-item {
		  display: flex;
		  justify-content: space-between;
		  margin-bottom: 0.75rem;
		  font-size: 0.95rem;
		  
		  @media (max-width: 768px) {
			font-size: 0.9rem;
		  }
		}
  
		.coupon-section {
		  margin: 1rem 0;
		  padding: 0.75rem;
		  background: #f8f9fa;
		  border-radius: 4px;
  
		  .coupon-input {
			display: flex;
			gap: 0.5rem;
  
			@media (max-width: 768px) {
			  flex-direction: column;
			}
  
			input {
			  flex: 1;
			  padding: 8px;
			  border: 1px solid #ddd;
			  border-radius: 4px;
			  font-size: 0.9rem;
			}
  
			button {
			  @media (max-width: 768px) {
				width: 100%;
				margin-top: 0.5rem;
			  }
			}
		  }
		}
  
		.total {
		  font-size: 1.1rem;
		  font-weight: 600;
		  margin-top: 1rem;
		  padding-top: 0.75rem;
		  border-top: 2px solid #eee;
  
		  @media (max-width: 768px) {
			font-size: 1rem;
		  }
		}
  
		.checkout-button {
		  width: 100%;
		  margin-top: 1rem;
		//   padding: 0.75rem;
		  font-size: 1rem;
  
		  @media (max-width: 768px) {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0;
			border-radius: 0;
			z-index: 1000;
			padding: 1rem;
		  }
		}
	  }
	}
  }
  .cart-container {
	max-width: 1200px;
	margin: 2rem auto;
	padding: 0 1rem;
	display: grid;
	grid-template-columns: 1fr 300px;
	gap: 2rem;
  
	@media (max-width: 768px) {
	  grid-template-columns: 1fr;
	}
  
	.cart-left {
		background: white;
		border-radius: 8px;
		box-shadow: 0 2px 4px rgba(0,0,0,0.1);
		overflow: hidden;

		@media (max-width: 768px) {
			box-shadow: none;
			border-radius: 0;
		}

		.vendoritems {
			overflow-x: scroll;
		}

		table {
			width: 100%;
			border-collapse: collapse;

			@media (max-width: 768px) {
				display: block;

				thead {
					display: none; // Hide table headers on mobile
				}

				tbody {
					display: block;
				}

				tr {
					display: block;
					margin-bottom: 1rem;
					border: 1px solid #eee;
					padding: 0.5rem;
				}

				td {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0.5rem;
					border: none;

					&::before {
						content: attr(data-label);
						font-weight: 600;
						margin-right: 1rem;
					}
				}
			}

			th, td {
				padding: 1rem;
				text-align: left;
				border-bottom: 1px solid #eee;

				@media (max-width: 768px) {
					padding: 0.5rem;
				}
			}

			th {
				background: #f8f9fa;
				font-weight: 600;
			}

			.cart-item {
				&:hover {
					background: #f8f9fa;
				}

				.vendor-info {
					display: flex;
					align-items: center;
					gap: 1rem;

					@media (max-width: 768px) {
						flex-direction: column;
						align-items: flex-start;
						gap: 0.5rem;
					}

					img {
						width: 40px;
						height: 40px;
						border-radius: 50%;

						@media (max-width: 768px) {
							width: 30px;
							height: 30px;
						}
					}
				}

				.quantity-controls {
					display: flex;
					align-items: center;
					gap: 0.5rem;

					@media (max-width: 768px) {
						justify-content: flex-end;
					}

					button {
						width: 30px;
						height: 30px;
						border-radius: 4px;
						border: none;
						background: #f0f0f0;
						cursor: pointer;

						@media (max-width: 768px) {
							width: 25px;
							height: 25px;
						}

						&:hover {
							background: #e0e0e0;
						}

						&:disabled {
							opacity: 0.5;
							cursor: not-allowed;
						}
					}

					.quantity {
						min-width: 40px;
						text-align: center;

						@media (max-width: 768px) {
							min-width: 30px;
						}
					}
				}
			}
		}
	}
  
	.cart-right {
	  background: white;
	  border-radius: 8px;
	  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
	  padding: 1.5rem;
	  height: fit-content;
	  position: sticky;
	  top: 2rem;
  
	  h2 {
		margin-bottom: 1.5rem;
		padding-bottom: 0.5rem;
		border-bottom: 2px solid #eee;
	  }
  
	  .summary-item {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		
		&.total {
		  margin-top: 1rem;
		  padding-top: 1rem;
		  border-top: 2px solid #eee;
		  font-size: 1.2rem;
		  font-weight: 600;
		}
	  }
  
	  .checkout-button {
		width: 100%;
		margin-top: 1rem;
	  }
	}
  }

  .product-item {
	position: relative;
	overflow: hidden;
  
	.add-to-cart-button {
	//   position: absolute;
	  bottom: -50px;
	  left: 0;
	  right: 0;
	  background: rgba(0, 0, 0, 0.85);
	  color: white;
	  padding: 7px 12px;
    border-radius: 16px;
    box-shadow: 0px 4px 8px gray;
    font-size: 13px;
    width: 100%;
	  border: none;
	  cursor: pointer;
	  transition: all 0.3s ease;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  gap: 8px;
	  font-weight: 500;
	  
	  &:hover {
		background: rgba(0, 0, 0, 0.95);
	  }
  
	  .cart-icon {
		font-size: 1.1em;
	  }
  
	  .button-text {
		transform: translateY(1px);
	  }
  
	  &.loading {
		background: rgba(0, 0, 0, 0.7);
		cursor: wait;
  
		.loader {
		  width: 20px;
		  height: 20px;
		  border: 2px solid rgba(255, 255, 255, 0.3);
		  border-top-color: white;
		  border-radius: 50%;
		  animation: spin 1s linear infinite;
		}
	  }
	}
  
	&:hover {
	  .add-to-cart-button {
		bottom: 0;
	  }
	}
  }
  
  @keyframes spin {
	to { transform: rotate(360deg); }
  }

  .delivery-type-selection {
	margin-bottom: 2rem;
	padding: 1.5rem;
	background: white;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
	h2 {
	  margin-bottom: 1rem;
	  font-size: 1.25rem;
	  color: #333;
	}
  
	.delivery-options {
	  display: grid;
	  grid-template-columns: 1fr 1fr;
	  gap: 1rem;
  
	  @media (max-width: 576px) {
		grid-template-columns: 1fr;
	  }
  
	  .delivery-option {
		padding: 1rem;
		border: 2px solid #eee;
		border-radius: 8px;
		background: white;
		cursor: pointer;
		transition: all 0.3s ease;
		font-weight: 500;
  
		&:hover {
		  border-color: #007bff;
		}
  
		&.selected {
		  border-color: #007bff;
		  background: #e7f1ff;
		  color: #007bff;
		}
	  }
	}
  }