.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 5px 10px; /* Equal spacing */
    padding: 10px;
    animation: slide 5s infinite linear;
  }
  
  .gallery-item {
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    animation: move 8s infinite alternate;
  }
  
  /* Smooth hover zoom effect */
  .gallery-item:hover {
    transform: scale(1.05);
  }
  
  /* Keyframes for moving effect */
  @keyframes move {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-20px);
    }
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-10px);
    }
  }
  