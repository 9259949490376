.error-boundary {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  
    .error-content {
      text-align: center;
      padding: 2rem;
      max-width: 600px;
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
      h1 {
        color: #dc3545;
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }
  
      p {
        color: #666;
        margin-bottom: 1.5rem;
      }
  
      button {
        min-width: 120px;
      }
    }
  }