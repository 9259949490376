.logo {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0;
  color: #fff;
  font-size: 1rem;
  @include desktop {
    font-size: 1.187rem;
  }

  .logoImage {
    margin-right: 10px;
    width: 100px;
    // height: 30px;
  }

  .logoText {
    display: none;
    @include mob-land {
      font-size: 0.9rem;
      display: block;
    }
    @include tab {
      font-size: 1rem;
    }
    @include desktop {
      font-size: 1.187rem;
    }
  }
}
