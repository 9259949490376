.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

button.loading {
  position: relative;
  padding-right: 40px;
  
  .spinner {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}