.app-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  background: #091D1B;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(100%);
  animation: slideUp 0.3s forwards;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
  }

  &__icon {
    width: 100px;
    // height: 100px;
    border-radius: 8px;
  }

  &__text {
    color: white;
    flex: 1;

    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      margin: 4px 0 0;
      font-size: 14px;
      opacity: 0.9;
    }
  }

  &__cta {
    background: white;
    color: #667eea;
    padding: 5px 16px;
    border-radius: 20px;
    text-decoration: none !important;
    font-weight: 600;
    font-size: 14px;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__close {
    color: white;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes slideUp {
  to {
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .app-banner {
    padding: 8px 16px;

    &__text {
      display: none;
    }
  }
}