.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
  & .form-body {
    position: relative;
    margin: 20px;
    width: 350px;
    background: #fff;
    padding-bottom: 10px;
    @include desktop {
      width: 400px;
    }
    @include retina {
      width: 500px;
    }

    .form__closeBtn {
      position: absolute;
      top: -11px;
      right: -12px;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      color: rgba(0, 0, 0, 0.9);
      background: #e6e5e5;
      font-size: 1.3rem;
      cursor: pointer;
      &:hover {
        color: rgba(255, 117, 117, 0.9);
      }
    }
    .input-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    .input-file {
      width: 100%;
    }

    form {
      padding: 15px;
    }

    h3 {
      font-size: 0.9rem;
      margin-bottom: 10px;
      padding: 15px;
      text-align: center;
      font-size: 1em;
      font-weight: 300;
      color: #fff;
      background: $dark-blue;
      box-shadow: 0 1px 1px #0f70e4;
      @include retina {
        font-size: 1.3rem;
      }
    }

    .two-sided-form {
      display: flex;
      flex-direction: column;
    }

    .form-sides {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
    }
    
    .form-side {
      flex: 1 0;
      min-width: 200px;
      max-width: 90%;
    }

    label {
      text-align: left;
      font-size: 0.8rem;
      width: 100%;
    }

    input, textarea,
    select {
      margin: 3px 0;
      border-radius: 3px;
      width: 100%;
      padding: 10px 10px;
      border: 1px solid #c2c2c2;
      outline: none;
      font-size: 0.8rem;
      @include desktop {
        font-size: 0.9rem;
      }
      @include retina {
        font-size: 1rem;
      }
      &:focus {
        border: 1px solid $dark-blue;
      }
      &:disabled {
        background: #e2e2e2;
      }
      &::placeholder {
        color: rgba(#000, 0.3);
      }
    }

    select {
      padding-right: 20px;
      background: url('../../img/select_dropdown.png') 95% 50% no-repeat;
      background-size: 10px 10px;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    button {
      margin-top: 5px;
      padding: 7px 15px;
      border: none;
      border-radius: 3px;
      outline: none;
      font-weight: 300;
      cursor: pointer;
      font-size: 1rem;

      @include retina {
        padding: 10px 15px;
        font-size: 1.1rem;
      }

      &#confirm-delete {
        color: #fff;
        background: #fa4e4e;
        margin-right: 5px;
        &:hover {
          background: lighten(#fa4e4e, 10%);
        }
        &:disabled {
          border: 1px solid #d1d1d1;
          background: transparent;
        }
      }

      &#cancel-action {
        color: #000;
        border: 1px solid #d1d1d1;
        &:hover {
          background: #f0f0f0;
        }
      }
    }
  }
}
