.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .contact-form {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  
    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
      color: #333;
    }
  
    .form-group {
        display: flex;
        flex-direction: column;
      margin-bottom: 1.5rem;
  
      label {
        display: block;
        margin-bottom: 0.5rem;
        color: #666;
      }
  
      input, textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 6px;
        transition: all 0.3s ease;
  
        &:focus {
          border-color: #22C4B5;
          box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
          outline: none;
        }
      }
  
      textarea {
        min-height: 150px;
      }
    }
  
    button {
      width: 100%;
    //   padding: 1rem;
    //   background: #007bff;
    //   color: white;
    //   border: none;
      border-radius: 6px;
      font-size: 1rem;
      cursor: pointer;
      transition: background 0.3s ease;
  
      &:hover {
        background: #22C4B5;
      }
    }
  }
  
  .contact-info {
    .info-card {
      background: white;
      padding: 1.5rem;
      border-radius: 10px;
      margin-bottom: 1.5rem;
      box-shadow: 0 5px 15px rgba(0,0,0,0.08);
      transition: transform 0.3s ease;
  
      &:hover {
        transform: translateY(-5px);
      }
  
      h3 {
        color: #333;
        margin-bottom: 1rem;
        font-size: 1.25rem;
      }
  
      p {
        color: #666;
        margin-bottom: 0.5rem;
        line-height: 1.6;
      }
  
      .icon {
        color: #22C4B5;
        margin-right: 0.5rem;
      }
    }
  }