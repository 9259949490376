@import '../../assets/styles/abstract/index';

.support-page {
  // font-family: Arial, sans-serif;
  font-family: 'Sansita';
  padding: 20px;
  background-color: #f9f9f9;
  max-width: 800px;
  margin: 0 auto;
}

h1, h2 {
  text-align: center;
  color: #333;
  font-family: 'Sansita';
}

.contact-methods {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.method {
  background: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 28%;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.method h3 {
  color: $deep-green;
}

.method a {
  color: #28a745;
  text-decoration: none;
  font-weight: bold;
}

.submit-ticket {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

form label {
  display: block;
  margin: 15px 0 5px;
  font-weight: bold;
  font-family: 'Sansita';
}

form input, form select, form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form button {
  background: $green;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 15px;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background: $deep-green;
}

.success-message {
  text-align: center;
  color: green;
  font-size: 16px;
  margin: 15px 0;
}
