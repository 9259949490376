.button {
    display: inline-flex;
	@include font-size(button);
    @include font-weight(button);
	padding: (($button-form--height - get-line-height(button) - ($button-form--border-width * 2)) / 2) ($button--padding-h - $button-form--border-width);
	height: $button-form--height;
    text-decoration: none !important;
	text-transform: $button--transform;
	color: color(button-light);
    background-color: color-bg(button-light);
    border-width: $button-form--border-width;
	border-style: $button-form--border-style;
	border-color: color-border(button-light);
    border-radius: $button--radius;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    letter-spacing: inherit;
    white-space: nowrap;
	transition: background .15s ease;

    &:active {
        outline: 0;
    }

	&:hover {
		background-color: color-bg(button-light-hover);
		border-color: color-border(button-light-hover);
	}

	&.is-loading {
		position: relative;
		color: transparent!important;
		pointer-events: none;

		&::after {
			content: '';
			display: block;
			position: absolute;
			width: get-line-height(button);
			height: get-line-height(button);
			margin-left: -(get-line-height(button) / 2);
			margin-top: -(get-line-height(button) / 2);
			top: 50%;
			left: 50%;
			border: $button-loading--thickness solid color(button-light);
			border-radius: 50%;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			z-index: 1;
			animation: button-loading .6s infinite linear;
		}
	}

	&[disabled] {
		cursor: not-allowed;
		color: color(button-disabled);
		background-color: color-bg(button-disabled) !important;
		border-color: color-border(button-disabled);

		&.is-loading {

			&::after {
				border-color: color(button-disabled);
			}
		}
	}
}

.button-sm {
    padding: (($button-form-sm--height - get-line-height(button) - ($button-form--border-width * 2)) / 2) ($button-sm--padding-h - $button-form--border-width);
	height: $button-form-sm--height;
}

.button-dark {
	color: color(button-dark);
    background-color: color-bg(button-dark);
	border-color: color-border(button-dark);

    &:hover {
        background-color: color-bg(button-dark-hover);
		border-color: color-border(button-dark-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-dark);
		}
	}
}

.button-primary {
	color: color(button-primary);
    background-color: color-bg(button-primary);
	border-color: color-border(button-primary);

    &:hover {
        background-color: color-bg(button-primary-hover);
		border-color: color-border(button-primary-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-primary);
		}
	}
}

.button-secondary {
	color: color(button-secondary);
    background-color: color-bg(button-secondary);
	border-color: color-border(button-secondary);

    &:hover {
        background-color: color-bg(button-secondary-hover);
		border-color: color-border(button-secondary-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-secondary);
		}
	}
}

.button-block {
    display: flex;
	width: 100%;
}

.button-group {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-right: -($button-group-padding / 2);
	margin-left: -($button-group-padding / 2);
	margin-top: -($button-group-padding / 2);

	&:last-of-type {
		margin-bottom: -($button-group-padding / 2);
	}

	&:not(:last-of-type) {
		margin-bottom: ($button-group-padding / 2);
	}

	> [class*=button] {
		margin: $button-group-padding / 2;
	}
}

@include media( '<=medium' ) {

	.button-wide-mobile {
		width: 100%;
		max-width: $button--max-size-mobile;
	}
}

.add-to-cart-btn {
    // position: relative;
    // padding: 12px 24px;
    // background: #007bff;
    // color: white;
    // border: none;
    // border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:disabled {
        // opacity: 0.7;
        cursor: not-allowed;
    }

    &.loading {
        padding-left: 40px;
    }
}

.loader {
    // position: absolute;
    // left: 15px;
    // top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border: 2px solid #dc3545;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
}

.error-message {
    color: #dc3545;
    margin-top: 10px;
    padding: 8px;
    border-radius: 4px;
    background: #f8d7da;
}

@keyframes spin {
    to { transform: translateY(-50%) rotate(360deg); }
}

@keyframes button-loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
