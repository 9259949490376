.google-sign-in {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    
    button {
      width: 100%;
      max-width: 300px;
      transition: transform 0.2s ease;
      
      &:hover {
        transform: translateY(-2px);
      }
    }
  }