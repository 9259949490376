.modal,
.modal::before {
	bottom: 0;
	left: 0;
	right: 0;
	top: 0
}


.modal {
	display: none;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	align-self: center;
	width: fit-content;
	position-area:center;
	// height: fit-content;
	overflow-y:scroll;
	overflow-x: hidden;
	scrollbar-width: none;
	position: fixed;
	z-index: 40;


	&.modal-video {

		.modal-inner {
			padding: 0;
			max-width: $modal-video--max-width;
		}
	}

	&::before,
	.modal-inner  {
		display: none;
	}

	&::before {
		content: '';
		position: absolute;
		background-color: color-bg(modal-outer);
	}

	&.is-active {
		display:flex;

		&::before,
		.modal-inner {
			display: block;
		}

		.modal-inner {
			animation: slideUpInModal .15s ease-in-out both;
		}

		&::before {
			animation: slideUpInModalBg .15s ease-in-out both;
		}
	}
}

.modal-inner {
	max-height: calc(100vh - #{$modal--outer-padding-v__mobile} * 2);
	overflow: auto;
	position: relative;
	width: calc(100%  - ( #{$container--padding__mobile} * 2 ));
	min-width: $modal--min-width;
	max-width: $modal--max-width;
	margin-left: $container--padding__mobile;
	margin-right: $container--padding__mobile;
	background: color-bg(modal);
}

.modal-content {
	padding: $modal--inner-padding-v__mobile $modal--inner-padding-h__mobile;
}

.modal-close {
	background: transparent;
	border: 0;
	outline: 0;
	padding: 0;
	cursor: pointer;
	position: absolute;
	right: $modal-close-position;
	top: $modal-close-position;
	width: $modal-close-icon--size;
	height: $modal-close-icon--size;

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -($modal-close-icon--thickness / 2);
		margin-left: -($modal-close-icon--size / 2);
		width: $modal-close-icon--size;
		height: $modal-close-icon--thickness;
		background: color-icon(modal);
		border-radius: $modal-close-icon--radius;
	}

	&::before {
		transform: rotate(225deg);
	}

	&::after {
		transform: rotate(-45deg);
	}

	&:hover {

		&::before,
		&::after {
			background: color-icon(modal-hover);
		}
	}
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);  /* or whatever color your overlay is */
	z-index: 40; /* Ensure it's on top of the page content, below modal inner */
  }

@include media( '>medium' ) {

	.modal-inner {
		margin: 0 auto;
		max-height: calc(100vh - #{$modal--outer-padding-v__desktop} * 2);
	}

	.modal-content {
		padding-top: $modal--inner-padding-v__desktop;
		padding-bottom: $modal--inner-padding-v__desktop;
		padding-left: $modal--inner-padding-h__desktop;
		padding-right: $modal--inner-padding-h__desktop;
	}
}

@keyframes slideUpInModal {
    0% {
        opacity: 0;
        transform: translateY(-24px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUpInModalBg {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.view-product-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
  
	.modal-content {
	  background: white;
	  border-radius: 8px;
	  width: 90%;
	  max-width: 1000px;
	  max-height: 90vh;
	  display: grid;
	  grid-template-columns: 1fr 1fr;
	  gap: 2rem;
	  padding: 2rem;
	  position: relative;
	  overflow: auto;
  
	  @media (max-width: 768px) {
		grid-template-columns: 1fr;
	  }
	}
  
	.close-button {
	  position: absolute;
	  top: 1rem;
	  right: 1rem;
	  background: none;
	  border: none;
	  font-size: 1.5rem;
	  cursor: pointer;
	  z-index: 1;
	}
  
	.product-gallery {
	  position: relative;
  
	  .product-image {
		width: 100%;
		height: 400px;
		object-fit: cover;
		border-radius: 4px;
	  }
  
	  .nav-button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background: white;
		border: none;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		cursor: pointer;
		opacity: 0.8;
  
		&.prev { left: 1rem; }
		&.next { right: 1rem; }
  
		&:hover {
		  opacity: 1;
		}
	  }
  
	  .image-thumbnails {
		display: flex;
		gap: 0.5rem;
		margin-top: 1rem;
  
		.thumbnail {
		  width: 60px;
		  height: 60px;
		  object-fit: cover;
		  border-radius: 4px;
		  cursor: pointer;
		  opacity: 0.6;
  
		  &.active {
			opacity: 1;
			border: 2px solid #007bff;
		  }
		}
	  }
	}
  
	.product-info {
	  padding: 1rem;
  
	  h2 {
		font-size: 1.5rem;
		margin-bottom: 1rem;
	  }
  
	  .price {
		font-size: 1.25rem;
		font-weight: bold;
		color: #007bff;
		margin-bottom: 1rem;
	  }
  
	  .description {
		margin-bottom: 2rem;
		line-height: 1.6;
	  }
  
	  .quantity-selector {
		display: flex;
		align-items: center;
		gap: 1rem;
		margin-bottom: 2rem;
  
		button {
		  width: 30px;
		  height: 30px;
		  border: 1px solid #ddd;
		  background: white;
		  border-radius: 4px;
		  cursor: pointer;
  
		  &:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		  }
		}
	  }
  
	  .add-to-cart {
		width: 100%;
		padding: 1rem;
		background: #007bff;
		color: white;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		transition: background 0.3s;
  
		&:hover {
		  background: #0056b3;
		}
	  }
	}
  }