@import '../../assets/styles/abstract/index';

#site-footer {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  & p {
    font-size: 0.8rem;
    @include desktop {
      font-size: 0.825rem;
    }
    @include retina {
      font-size: 0.9rem;
    }
  }
}
