.orders-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;

    .order-group {
        background: white;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        margin-bottom: 2rem;
        padding: 1.5rem;

        .order-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            
            .status {
                padding: 0.5rem 1rem;
                border-radius: 20px;
                font-weight: 500;
                
                &.pending { background: #fff3cd; }
                &.ready { background: #d1e7dd; }
                &.completed { background: #cfe2ff; }
                &.cancelled { background: #f8d7da; }
            }
        }

        .vendor-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
            color: #666;
        }

        .order-items {
            display: grid;
            gap: 1rem;
            margin: 1rem 0;

            .order-item {
                display: flex;
                gap: 1rem;
                padding: 1rem;
                border: 1px solid #eee;
                border-radius: 4px;

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 4px;
                }

                .item-details {
                    flex: 1;
                }
            }
        }

        .order-actions {
            display: flex;
            gap: 1rem;
            margin-top: 1rem;
        }
    }

    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        z-index: 1000;
    }
}