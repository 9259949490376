@import '../../assets/styles/abstract/index';

.aside {
  display: flex;
  margin-top: 20px;
  background: #fbfbfb;
  box-shadow: 0px 4.71062px 23.5531px rgba(0, 0, 0, 0.07);
  overflow-x: scroll;

  @include desktop {
    width: 20%;
    display: block;
    overflow-x: unset;
  }

  &__nav {
    width: 100%;

    ul {
      display: flex;
      @include desktop {
        display: block;
      }

      li {
        width: 50%;
        @include desktop {
          width: 100%;
        }

        a {
          display: block;
          padding: 15px 15px;
          color: #2c363b;
          font-size: 0.7rem;
          text-align: center;
          text-decoration: none;
          @include desktop {
            text-align: left;
            font-size: 0.9rem;
          }
          @include retina {
            font-size: 1rem;
          }

          &:hover {
            background: #091D1B;
            color: #fff;
          }

          &.selected {
            background: #091D1B;
            color: #fff;
            border-bottom: 5px solid $green;
            @include desktop {
              border: none;
              border-left: 5px solid $green;
            }
            &:hover {
              background: #091D1B;
            }
          }
        }
      }
    }
    .dropdown-header {
      cursor: pointer;
      padding: 10px;
      font-weight: bold;
      color: #333;
    }
  
    .dropdown {
      list-style-type: none;
      margin: 0;
      padding: 0 15px;
  
      .dropdown-item {
        padding: 8px 0;
  
        a {
          text-decoration: none;
          color: #555;
  
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  
    .selected {
      color: #007bff;
      font-weight: bold;
    }
  }
}

