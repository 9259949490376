.terms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  
    .terms-content {
      background: #fff;
      padding: 3rem;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      line-height: 1.6;
  
      h2 {
        font-size: 2rem;
        color: #2c3e50;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 2px solid #eee;
      }
  
      h3 {
        font-size: 1.5rem;
        color: #34495e;
        margin: 2rem 0 1rem;
      }
  
      p {
        color: #555;
        margin-bottom: 1.2rem;
        font-size: 1.1rem;
      }
  
      ul {
        list-style: none;
        padding-left: 1rem;
        margin: 1.5rem 0;
  
        li {
          margin-bottom: 1rem;
          position: relative;
          padding-left: 1.5rem;
  
          &:before {
            content: "•";
            color: #3498db;
            position: absolute;
            left: 0;
          }
  
          ul {
            margin: 0.5rem 0;
          }
        }
      }
  
      a {
        color: #3498db;
        text-decoration: none;
        transition: color 0.3s ease;
  
        &:hover {
          color: #2980b9;
          text-decoration: underline;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 1rem;
  
      .terms-content {
        padding: 1.5rem;
  
        h2 {
          font-size: 1.75rem;
        }
  
        h3 {
          font-size: 1.25rem;
        }
  
        p {
          font-size: 1rem;
        }
      }
    }
  }