.products-list {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
    grid-gap: 15px 30px; /* Equal spacing */
    padding: 10px;
    animation: slide 5s infinite linear;
  }
  
  .product-item {
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .product-item img {
    width: 100%;
    height: 200px;
    display: block;
    object-fit: cover;
    // animation: move 8s infinite alternate;
  }
  .item-bottom{
left: 0%;
right: 0%;
top: 67.49%;
bottom: 0%;
padding: 15px;
padding-bottom: 30px;
background: #dff3ee;
  }
  .item-bottom p {
    margin: 0;
    padding: 0;
  }
.item-name{
font-family: 'Sansita';
font-style: normal;
font-weight: 600;
font-size: .8rem;
color: #3A3A3A;
text-align: left;
}
.item-desc{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 13px;
color: #898989;
}

.item-price {
font-family: 'Poppins';
font-style: normal;
// font-weight: 600;
font-size: .75rem;
color: #3A3A3A;
flex: none;
order: 0;
flex-grow: 0;
display: flex;
justify-content: space-between;

}

.item-oldPrice{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
text-decoration-line: line-through;
// color: #989898;
color: rgba(255, 0, 0, 0.644);
flex: none;
order: 1;
flex-grow: 0;
}
.discount-label{
position: absolute;
// left: 74.74%; 
padding: 5px 5px;
right: 8.42%;
bottom: 83.86%;
background: #E97171;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 13px;
color: #FFFFFF;
}
.new-label{
  position: absolute;
  padding: 5px 5px;
  right: 8.42%;
  // top: 5.38%;
  bottom: 83.86%;
  background: #2EC1AC;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #FFFFFF;
}
  
  /* Smooth hover zoom effect */
  .product-item:hover {
    // transform: scale(1.05);
  }
  
  .shop.products-list {
    padding: 10px 60px;
    grid-gap: 15px 15px; /* Equal spacing */
  }
.product-details{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.image-section{
  display: flex;
  // width: 100%;
  margin-right: 20px;
}
.images-list{
  display: flex;
}
.images-list ul {
  padding: 0;
}
.prod-image{
width: 76px;
height: 80px;
// left: 99px;
// top: 246px;
background: #D9D9D9;
border-radius: 10px;
margin: 15px 5px;
object-fit: cover;
}
.main-prod-image{
  margin: 20px 5px;
  width: 350px;
  height: 350px;
  object-fit: cover;
  background: #F9F1E7;
  border-radius: 10px;
}
.desc-section{
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 20px;
  width: 100%;
}
  /* Keyframes for moving effect */
  @keyframes move {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-20px);
    }
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-10px);
    }
  }
  
  @media (max-width:768px) {
    .shop.products-list {
      padding: 0px;
    }
    .products-list {
      grid-template-columns: repeat(auto-fit, minmax(145px, 2fr));
      grid-gap: 25px 50px; /* Equal spacing */
    }
    .image-section{
      flex-direction: column;
    }
    .images-list{
      order: 2;
    }
    .images-list ul {
      display: flex;
       gap: 10px;
       overflow-x: scroll;
       scrollbar-width: none;
    }
  }