@import '../../assets/styles/abstract/index';
.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 2rem;
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);

  &__image {
    max-width: 400px;
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    animation: float 6s ease-in-out infinite;
  }

  &__message {
    font-size: 2rem;
    color: #2d3436;
    margin-bottom: 2rem;
    font-weight: 600;
    text-shadow: 1px 1px 0 rgba(0,0,0,0.1);
  }

  &__button {
    padding: 1rem 2.5rem;
    font-size: 1.1rem;
    color: white;
    background: #007bff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0,123,255,0.3);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 20px rgba(0,123,255,0.4);
      background: #0056b3;
    }

    &:active {
      transform: translateY(0);
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 768px) {
  .notfound {
    &__message {
      font-size: 1.5rem;
    }
    
    &__image {
      max-width: 300px;
    }
  }
}