.search-container {
  padding: 20px;
  margin-bottom: 30px;
  
  .search-wrapper {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    
    .search-icon {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: #666;
    }
    
    .search-input {
      width: 100%;
      padding: 12px 20px 12px 45px;
      border: 2px solid #eee;
      border-radius: 25px;
      font-size: 16px;
      transition: all 0.3s ease;
      
      &:focus {
        border-color: #091D1B;
        outline: none;
        box-shadow: 0 0 0 3px rgba(0,123,255,0.25);
      }
    }
  }
}

.shop-container {
  padding: 20px;

  .shop-layout {
    display: grid;
    // grid-template-columns: 250px 1fr;
    gap: 30px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .categories-sidebar {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    h3 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid #eee;
    }
  }

  .categories-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s ease;

      &:hover {
        background: #f8f9fa;
        color: #091D1B;
      }

      &.active {
        background: #091D1B;
        color: white;
        border-radius: 4px;

        .category-count {
          background: white;
          color: #091D1B;
        }
      }
    }

    .category-count {
      font-size: 0.8rem;
      background: #f8f9fa;
      padding: 2px 8px;
      border-radius: 12px;
    }
  }
}

.show {
  display: block !important;
}
.categories-sidebar {
  @media (max-width: 768px) {
    display: none;

    &.active {
      left: 0; /* Slide in when active */
    }
  }
}

