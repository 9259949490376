@import '../../assets/styles/abstract/index';

.payments-page {
  padding: 20px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  section {
    margin-bottom: 40px;

    h2 {
      margin-bottom: 10px;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin: 5px 0;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: left;
      }

      th {
        background-color: #f9f9f9;
      }
    }

    button {
      padding: 8px 12px;
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
.summary {
  justify-content: space-between;
  border-bottom: 1px solid rgba(rgb(238, 238, 238), 0.3);
  padding-bottom: 10px;
  margin-bottom: 15px;

  @include desktop {
    display: flex;
  }

  &__card {
    width: 100%;
    // border-radius: 15px;
    margin-bottom: 10px;
    box-shadow: 0 1px 20px rgba(#000, 0.1);
    // background: linear-gradient(90deg, #fbfbfb 65%, #f5f5f5 50%);
    display: flex;
    justify-content: space-between;
    padding: 5px;

    @include desktop {
      width: 31%;
    }

    .details {
      padding-left: 20px;
      padding-top: 10px;
      .header {
        padding-top: 10px;
        font-weight: 300;
        font-size: 0.85rem;
      }
      .value {
        text-align: center;
        padding-top: 6px;
        font-size: 1.1rem;
        @include desktop {
          font-size: 1.5rem;
        }
      }
    }
    .icon {
      border-radius: 10px;
      padding: 10px;
      &.yellow {
        background: $yellow;
      }
      &.red {
        background: #ff6767;
      }
      &.green {
        background: #54ea8e;
      }
      img {
        width: 64px;
        height: 64px;
      }
    }
  }
  .admin-sales {
    width: 100%;
    @include desktop {
      width: 66%;
    }
  }
  .prod-cat-summary {
    width: 100%;
    margin-top: 10px;
    @include desktop {
      width: 32%;
      margin-top: 0;
    }
    & .summary__card {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
