@import '../../assets/styles/abstract/index';

#site-header {
  height: 60px;
  width: 100%;
  background: $green;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top-nav {
    display: flex;
    height: 100%;
    align-items: center;
    ul {
      display: flex;
      li {
        margin-left: 10px;
        display: flex;
        align-items: center;
        &.cart {
          position: relative;
          padding-right: 10px;
          .cart__image {
            width: 25px;
            height: 25px;
          }
          .cart__count {
            position: absolute;
            top: -10px;
            right: -1px;
            content: '0';
            font-size: 13px;
            color: $deep-black;
            border-radius: 10px;
            background: #54ea8e;
            padding: 0px 5px;
            display: block;
          }
          &__header {
            text-align: center;
            letter-spacing: 7px;
          }
        }
        a {
          color: #fff;
          font-size: 0.8rem;
          display: flex;
          @include desktop {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
