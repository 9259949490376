.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hero-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 700px; /* Adjust height as needed */
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slide.active {
  opacity: 1;
}

.content {
  position: absolute;
    /* bottom: 20px; */
    height: 700px;
    width: 100%;
    /* top: 100px; */
    display: flex;
    padding: 100px;
    /* left: 20px; */
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .3);
}

h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 1.5em;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Sansita';
  src: local('Sansita'), url(./assets/fonts/Sansita/Sansita-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Sansita Bold';
    font-weight: 900;
    src: local('Sansita Bold'), url(./assets/fonts/Sansita/Sansita-Bold.ttf) format('opentype');
}

.home-container {
  .hero-section {
    min-height: 80vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem;
    background: linear-gradient(to right, #fff, #f8f9fa);
    align-items: center;
  
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      text-align: center;
      padding: 1rem;
    }

    .hero-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.5rem;

      .hero-title {
        font-size: 3.5rem;
        font-weight: 700;
        color: #091D1B;
        line-height: 1.2;
      }

      .hero-subtitle {
        font-size: 1.2rem;
        color: #666;
      }

      .hero-cta {
        display: flex;
        gap: 1rem;

        @media (max-width: 768px) {
          justify-content: center;
        }
      }
    }
  }

  .featured-categories {
    padding: 4rem 2rem;
    
    .categories-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 2rem;
      margin-top: 2rem;
    }
    
    .category-card {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      h3 {
        position: relative;
        font-size: 1em;
        text-align: center;
        bottom: 0;
        left: 0;
        color: #091D1B;
        right: 0;
        padding: .5rem;
        background: rgba(255,255,255,0.9);
        margin: 0;
      }
    }
  }

  .value-props {
    background: #f8f9fa;
    padding: 4rem 2rem;

    .value-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 2rem;
      text-align: center;

      .value-item {
        padding: 2rem;

        svg {
          font-size: 2rem;
          color: #091D1B;
          margin-bottom: 1rem;
        }

        h3 {
          font-size: 1.3rem;
          /* color: #091D1B; */
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .trending-products {
    padding: 4rem 2rem;

    .products-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 2rem;
      margin-top: 2rem;
    }

    .product-card {
      padding: 1rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      /* text-align: center; */

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 4px;
      }

      .price {
        color:tomato;
        font-weight: 600;
        margin: 1rem 0;
      }
    }
  }
}

.hero-carousel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);

  .carousel-slide {
    position: relative;
    height: 500px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .slide-content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 2rem;
      background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
      color: white;

      h2 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1.1rem;
        opacity: 0.9;
      }
    }
  }

  .slick-dots {
    bottom: 20px;

    li {
      button:before {
        color: white;
        opacity: 0.5;
        font-size: 10px;
      }

      &.slick-active button:before {
        opacity: 1;
      }
    }
  }

  .slick-prev, .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;

    &:before {
      font-size: 40px;
    }
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }

  @media (max-width: 768px) {
    height: 300px;

    .carousel-slide {
      height: 300px;

      .slide-content {
        padding: 1rem;

        h2 {
          font-size: 1.5rem;
        }

        p {
          font-size: 1rem;
        }
      }
    }
  }
}