.store-setup {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  
    h2 {
      text-align: center;
      margin-bottom: 20px;
      color: #333;
    }
  
    h3 {
      color: #444;
      margin-bottom: 15px;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      label {
        margin-bottom: 15px;
  
        display: flex;
        flex-direction: column;
        font-weight: 600;
        font-size: 14px;
        color: #555;
  
        input,
        textarea,
        select {
          margin-top: 8px;
          padding: 10px;
          font-size: 14px;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
          background-color: #fff;
          transition: border 0.3s ease;
  
          &:focus {
            border-color: #007bff;
          }
        }
  
        textarea {
          resize: vertical;
        }
  
        input[type='file'] {
          padding: 2px;
        }
      }
  
      .form-navigation {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
  
        button {
          padding: 10px 20px;
          font-size: 14px;
          font-weight: bold;
          color: #fff;
          background: linear-gradient(to right, #007bff, #0056b3);
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background 0.3s ease;
  
          &:hover {
            background: linear-gradient(to right, #0056b3, #003d80);
          }
  
          &:disabled {
            background: #ccc;
            cursor: not-allowed;
          }
        }
      }
    }
  
    // Error handling styles
    .error {
      color: #d9534f;
      font-size: 13px;
      margin-top: 5px;
    }
  
    // File input hint
    input[type='file']::file-selector-button {
      padding: 5px 10px;
      font-size: 13px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  