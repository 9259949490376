.cart__header {
  text-align: center;
  letter-spacing: 2px;
}

.blue-bg {
  background: #f3f8ff;
  @include desktop {
    background: radial-gradient(circle, #ffb292,#f3f8ff 70%);
    padding: 10px;
    font-size: 1rem;
  }
}

.grey-bg {
  background: #f2f2f2;
}

.full-width {
  width: 100%;
}

.hide {
  display: none;
}

.float-right {
  float: right;
}
