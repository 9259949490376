@import '../../assets/styles/abstract/index';

.vendor-profile {
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

.vendor-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.vendor-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
}

.vendor-info {
  flex: 1;
}

.vendor-name {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
}

.vendor-description,
.vendor-location {
  margin: 10px 0;
}

.vendor-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.vendor-section {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
}

.vendor-section h3 {
  margin-bottom: 10px;
}

.vendor-products {
  margin-top: 20px;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.product-name {
  margin: 10px 0 5px;
}

.product-price {
  color: green;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .vendor-details {
    grid-template-columns: 1fr;
  }

  .vendor-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .vendor-avatar {
    margin-bottom: 15px;
  }

  .vendor-info {
    text-align: center;
  }
}
