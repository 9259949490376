.policy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    
    .policy-navigation {
      position: sticky;
      top: 20px;
      background: white;
      padding: 1rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      margin-bottom: 2rem;
      
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 1rem;
        overflow-x: auto;
        
        li a {
          color: #666;
          text-decoration: none;
          white-space: nowrap;
          
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  
    .policy-content {
      background: white;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
      h1, h2, h3 {
        color: #333;
        margin-bottom: 1.5rem;
      }
  
      h1 { font-size: 2rem; }
      h2 { font-size: 1.5rem; }
      h3 { font-size: 1.25rem; }
  
      p {
        color: #666;
        line-height: 1.6;
        margin-bottom: 1rem;
      }
  
      ul {
        list-style: disc;
        margin-left: 1.5rem;
        margin-bottom: 1rem;
        
        li {
          color: #666;
          margin-bottom: 0.5rem;
        }
      }
  
      section {
        margin-bottom: 3rem;
        
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }