@import '../../assets/styles/abstract/index';

.form__wrapper {
  width: 100%;
  padding: 10vh 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  .form__sidepane {
    display: none;
    width: 75vh;
    border-radius: 0px 10px 10px 0px;
    background: url('../../assets/img/shop-img.jpg') no-repeat;
    background-size: cover;
    @include tab {
      display: block;
    }
  }
  .form {
    padding: 0 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 320px;
    @include tab {
      padding: 0 50px;
      width: 450px;
      background: #f7fbff;
    }
    &__logo {
      margin-top: 45px;
      width: 100px;
      text-align: center;
      padding-bottom: 2px;
      // border-bottom: 2px solid #4297ff;
    }
    &__appname {
      margin-top: 10px;
      font-size: 1.2em;
      @include desktop {
        font-size: 1.5em;
      }
    }
    &__description {
      margin-top: 10px;
      font-size: 0.8em;
      text-align: center;
      @include desktop {
        font-size: 1em;
      }
    }
    &__header {
      margin-bottom: 10px;
      padding-top: 50px;
      text-align: center;
      font-size: 1em;
      font-weight: 300;
      color: rgb(39, 39, 39);
    }
    &__login {
      width: 100%;
    }
    &__control {
      margin-bottom: 6px;
    }
    &__input {
      font-family: inherit;
      padding: 10px;
      width: 100%;
      outline: none;
      border: none;
      border: 1px solid #e6e5e5;
      font-size: 0.9em;
      transition: border 0.5s ease;
      color: #000;
      border-radius: 3px;
      &::placeholder {
        color: rgba(#000, 0.3);
      }
      &:focus {
        border: 1px solid $dark-blue;
        box-shadow: 0px 1px 15px 3px #e6e5e5;
      }
      @include retina {
        padding: 10px;
        font-size: 1rem;
      }
    }
  }
}
