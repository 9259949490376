@import '../../assets/styles/abstract/index';

.support-page {
  // font-family: Arial, sans-serif;
  font-family: 'Sansita' !important;
  padding: 20px;
  background-color: #ffffff;
  min-width: 50%;
  max-width: 800px;
  margin: 10px auto;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab-button {
  flex: 1;
  padding: 10px;
  text-align: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.tab-button.active {
  border-bottom: 2px solid $green;
  color: $green;
}

.tab-content {
  margin-top: 20px;
}

.tab-section {
  display: none;
}

.tab-section.active {
  display: block;
}

h2 {
  font-size: 20px;
  margin-bottom: 15px;
  font-family: 'Sansita';
}

p {
  font-size: 16px;
}