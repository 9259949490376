.btn {
  display: inline-block;
  position: relative;
  font-family: inherit;
  border: none;
  outline: none;
  font-size: 0.9rem;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  background: #eee;

  @include desktop {
    font-size: 1rem;
  }

  &__primary {
    background: $dark-blue;
  }

  &--blue {
    background: $dark-blue;
  }

  &--orange {
    background: $orange;
    cursor: pointer;
    &:hover {
      background: darken($orange, 10%);
    }
  }

  &:disabled {
    background: #fbfbfb;
    border: 1px solid #ddd;
    color: #d0d0cf;
    box-shadow: none;
    &.btn--gradient {
      background: #fbfbfb;
    }
  }

  &--gradient {
    background: linear-gradient(45deg, $green, #0b3901);
    &:hover {
      background: linear-gradient(266deg, $green 10%, #0b3901 80%);
    }
  }
}
